import React, { useContext } from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import { navigate } from 'gatsby';
import map from 'lodash/map';
import toArray from 'lodash/toArray';
import groupBy from 'lodash/groupBy';
import { Helmet } from 'react-helmet';
import cartEmpty from '../../assets/icons/cart-empty.png';
import remove from '../../assets/icons/remove.svg';
import './cart.scss';
import { transformPrice } from '../../utils/formattes';
import { transform } from '../../utils/dateFormatted';
import { newTicket } from '../../utils/ticketsUtils';
import LotteriesContext from '../../state/LotteriesContext';
import ApplicationContext from '../../state/ApplicationContext';
import CartContext from '../../state/CartContext';
import spinner from '../../assets/images/spinner.gif';

const toggleArrayValue = (array, value) => {
  const newArray = [...array];
  const index = newArray.indexOf(value);

  if (index === -1) {
    newArray.push(value);
    return newArray;
  }
  newArray.splice(index, 1);
  return newArray;
};
const quickPickGames = [
  {
    name: 'Euro Jackpot',
    id: 7,
  },
  {
    name: 'El Gordo',
    id: 6,
  },
  {
    name: 'MegaMillions',
    id: 4,
  },
  {
    name: 'US Powerball',
    id: 1,
  },
  {
    name: 'EuroMillions',
    id: 2,
  },
];

const Cart = injectIntl(({ intl: { formatMessage } }) => {
  const {
    handleGrowl, setShowSignIn,
  } = useContext(ApplicationContext);
  const {
    cartItems, setCartItems, totalPrice, powerPlayItems, setPowerPlayItems, isLoaded
  } = useContext(CartContext);

  const translate = (str) => (formatMessage({id: str}))
  if (typeof window !== 'undefined') {
    const userData = localStorage.getItem('userData');
    if (!userData) {
      handleGrowl(true, 'To view this page, you must log in to your account', false);
      navigate('/');
      setShowSignIn(true);
      return null;
    }
  }
  const { lotteries } = useContext(LotteriesContext);

  const handleRemoveTicket = (ticket, cardsIndex) => {
    const newCartItems = cartItems.map((element, index) => {
      if (index === cardsIndex) {
        const newGameArray = element.map((durationCards) => {
          if ((durationCards.find((card) => card.id === ticket.id))) {
            const newDurationCards = durationCards.filter((ticketsNumbers) => ticketsNumbers.id !== ticket.id);
            return newDurationCards;
          }
          return durationCards;
        });
        return newGameArray.filter((game) => Boolean(game.length));
      }
      return element;
    });

    const cartItemsFromLocalStorage = localStorage.getItem('cartItems');
    if (cartItemsFromLocalStorage) {
      const parsedCartItems = JSON.parse(cartItemsFromLocalStorage).filter((element) => ticket.id !== element.id);
      localStorage.setItem('cartItems', JSON.stringify(parsedCartItems));
    }
    setCartItems(newCartItems.filter((element) => Boolean(element.length)));
  };
  const handleClickBack = () => {
    navigate('/');
  };
  const handleQuickPick = (id) => {
    const game = lotteries.find((element) => element.id === id);
    const randomTicket = newTicket(game, true);
    const cartItems = localStorage.getItem('cartItems');
    if (cartItems) {
      const parsedCart = JSON.parse(cartItems);
      parsedCart.push(randomTicket);
      localStorage.setItem('cartItems', JSON.stringify([...parsedCart]));
      const gruppedByGameId = toArray(groupBy(parsedCart, (ticket) => ticket.idGame));
      const gruppedByDutaion = gruppedByGameId.map((element) => toArray(groupBy(element, (ticket) => ticket.duration)));
      setCartItems(gruppedByDutaion);
    }
    if (!cartItems) {
      localStorage.setItem('cartItems', JSON.stringify([randomTicket]));
    }
  };
  const handleChangePowerPlay = (index) => {
    const newPowerPlayArray = toggleArrayValue(powerPlayItems, index);
    setPowerPlayItems(newPowerPlayArray);
  };

  return (
    <div className="cart-page-wrapper">
      <Helmet>
        <title>
          Lotteroo | {translate('Cart')}
        </title>
      </Helmet>

      <div className="top-block-profile">
        <div className="title">{formatMessage({ id: 'Cart' })}</div>
      </div>

      <div className="page-content">
        <div className="cart">
          <div className="cart-title-wrap">
            <div className="cart-title">{formatMessage({ id: 'Cart' })}</div>
            <ul className="quick-pick">
              <li className="quick-pick-label">{formatMessage({ id: 'Add QuickPick' })}</li>
              {quickPickGames.map((element) => (
                <li className="quick-pick-game" onClick={() => handleQuickPick(element.id)}>
                  {element.name}
                </li>
              ))}
            </ul>
          </div>
          <div className="list">
            {isLoaded ? <>
              {cartItems.length ? (
                <ul>
                  {cartItems.map((gameCards, itemIndex) => gameCards.map((element, gameIndex) => {
                    const { duration } = element[0];
                    const newDurations = map(element[0].game.prices, (price: any) => {
                      price = {
                        ...price,
                        durationPrice: (element[0].idGame === 1 && powerPlayItems.includes(gameIndex) ? price.priceMultiplier : price.pricePerLine) * (price.quantity >= 8 ? 8 : 1),
                      };
                      return price;
                    });
                    const selectedDuration = newDurations.find((durationItem) => durationItem.quantity === duration);
                    const computedPrice = (selectedDuration.durationPrice * element.length) * (selectedDuration.quantity >= 8 ? selectedDuration.quantity / 8 : selectedDuration.quantity);
                    return (
                      <li key={gameIndex}>
                        <div className="left-block">
                          <div className="logo">
                            <img src={element[0].game.logoImgUrl} alt="" />
                          </div>
                          <div className="info-wrap">
                            <div className="title">
                              <span>{element[0].game.lotteryTitle}</span>
                              {' '}
                              -
                              {' '}
                              <span>{transformPrice(element[0].game.jackpot, 'amountForHotGame')}</span>
                            </div>
                            <div className="info">

                              <div className="duration">
                                {element[0].duration}
                                {' '}
                                {translate('draw')}
                              </div>
                              {element.map((currentTicket) => (
                                <div className="selected-numbers">
                                  {currentTicket.mainNumbers.map((num) => (
                                    `${num}, `
                                  ))}
                                  {currentTicket.bonusNumbers.map((num, index) => (
                                    <span>{`${num}${currentTicket.bonusNumbers.length - 1 === index ? '' : ', '}`}</span>
                                  ))}
                                  <div className="remove" onClick={() => handleRemoveTicket(currentTicket, itemIndex)}>
                                    <img src={remove} alt="" />
                                  </div>
                                </div>
                              ))}
                              <div className="partic-on">
                                {translate('Next Draw:')}
                                {' '}
                                {transform(element[0].game.nextDrawTime, 'fullDate')}
                              </div>


                            </div>
                          </div>
                        </div>
                        <div className="right-block">
                          {element[0].idGame === 1 && (
                            <div className="powerplay">
                              <div className="input-wrap">
                                <input
                                  type="checkbox"
                                  className="custom-input"
                                  name="powerplay"
                                  onChange={() => handleChangePowerPlay(gameIndex)}
                                  id={`check-${gameIndex}`}
                                  checked={powerPlayItems.includes(gameIndex)}
                                />
                                <label htmlFor={`check-${gameIndex}`} className="label" />
                              </div>
                              <div className="option">
                                <label htmlFor={`check-${gameIndex}`}>  {translate('Power Play')}</label>
                              </div>
                            </div>
                          )}
                          <div className="line-total">
                            {transformPrice(computedPrice, 'forCart')}
                          </div>
                        </div>
                      </li>
                    );
                  }))}
                </ul>
              ) : (
                <div className="empty-cart">
                  <div className="empty-cart-img">
                    <img src={cartEmpty} alt="" />
                  </div>
                  <div className="empty-cart-text">{translate('No items in cart.')}</div>
                  <div className="empty-cart-btn">
                    <button className="green-btn" onClick={handleClickBack}>
                      {translate('Browse Games')}
                    </button>
                  </div>
                </div>
              )}
            </>: <div className={'cart-preloader'}>
              <img src={spinner} alt=""/>
            </div>}
          </div>

          <div className="promo-total-wrap">
            <div className="cart-total">
              <span className="label">{formatMessage({ id: 'Total price:' })}</span> <span className="amount">{transformPrice(totalPrice, 'forCart')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const WrappedCart = () => <Cart />;

export default WrappedCart;
